import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import axios from "axios";
import { Helmet } from "react-helmet";

// Contact form
import FormUserInformation from './components/contact-form/Form_user-information'
import FormChooseDealer from './components/contact-form/Form_choose-dealer'
import FormSubject from './components/contact-form/Form_question'
import FormSummary from './components/contact-form/Form_summary'
import FormSuccess from './components/contact-form/Form_success'

// Service form
import ServiceFormUserInformation from './components/service-form/Form_user-information'
import ServiceCarInfo from './components/service-form/Form_car-info'
import ServiceChooseDealer from './components/service-form/Form_choose-dealer'
import ServiceChooseTime from './components/service-form/Form_choose-time'
import ServiceSummary from './components/service-form/Form_summary'
import ServiceSuccess from './components/service-form/Form_success'

// Test drive form
import TestDriveFormUserInformation from './components/test-drive-form/Form_user-information'
import TestDriveFormChooseModel from './components/test-drive-form/Form_choose-model'
import TestDriveFormChooseDealer from './components/test-drive-form/Form_choose-dealer'
import TestDriveFormChooseTime from './components/test-drive-form/Form_choose-time'
import TestDriveFormSummary from './components/test-drive-form/Form_summary'
import TestDriveFormSuccess from './components/test-drive-form/Form_success'

// Driving academy form
import AcademyFormUserInformation from './components/driving-academy-form/Form_user-information'
import AcademyFormChooseEvent from './components/driving-academy-form/Form_choose-event'
import AcademyFormChooseTime from './components/driving-academy-form/Form_choose-time'
import AcademyFormSummary from './components/driving-academy-form/Form_summary'
import AcademyFormSuccess from './components/driving-academy-form/Form_success'

// Preorder form
import PreorderUserInformation from './components/preorder-form/Form_user-information'
import PreorderChooseDealer from './components/preorder-form/Form_choose-dealer'
import PreorderSummary from './components/preorder-form/Form_summary'
import PreorderSuccess from './components/preorder-form/Form_success'

// Loterija form
import LotteryUserInformation from './components/lottery-form/Form_user-information'
import LotterySuccess from './components/lottery-form/Form_success'

//Sidebar
import SidebarContact from './components/contact-form/Form_sidebar'
import SidebarService from './components/service-form/Form_sidebar'
import SidebarTestDrive from './components/test-drive-form/Form_sidebar'
import SidebarAcademy from './components/driving-academy-form/Form_sidebar'
import SidebarPreorder from './components/preorder-form/Form_sidebar'
import SidebarLottery from './components/lottery-form/Form_sidebar'

let lang = 'lv';
let country = 'lv';
const url = new URL(window.location.href);
const searchParams = new URLSearchParams(url.search);
const p = window.location.pathname;

// Listen to messages from parent window, save in localstorage
localStorage.setItem('campaign', '');
window.addEventListener('message', function(event){
  if (typeof event.data == "string" && (event.data.indexOf('ac_') > -1 || event.data.indexOf('audi_camp_') > -1 || event.data == '5plus')) {
    localStorage.setItem('campaign', event.data);
  }
});

// Campaign parameter from direct URL
if (searchParams.get('campaign')) {
    localStorage.setItem('campaign', searchParams.get('campaign'));
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			translations: [],
			dealers: [],
			models: [],
      events: [],
			settings: [],
			formStep: false,
			showSidebar: true,
			disabledDealerStep: false,
      disabledModelStep: false,
			showContactSidebar: false,
			showServiceSidebar: false,
			showTestDriveSidebar: false,
      showAcademySidebar: false,
      showPreorderSidebar: false,
      showLotterySidebar: false
		}
	}

	componentDidMount() {

		// RU
		if (p.includes('/ru/')) {
			lang = 'ru';
		}

		// LT
		if (p.includes('/lt/lt/') || p.includes('/lt/ru/')) {
			country = 'lt';
			if (p.includes('/lt/lt/')) {
				lang = 'lt';
			}
		}

		// EE
		if (p.includes('/ee/ee/') || p.includes('/ee/ru/')) {
			country = 'ee';
			if (p.includes('/ee/ee/')) {
				lang = 'ee';
			}
		}

		this._loadData(lang, country);

		// Disable dealer step, if form ir opened from dealer site
		if (searchParams.get('dealer') > 0) {

            // Custom dealer select if dealer 864
            if(searchParams.get('dealer') !== '864' || !p.includes('service')){
    			this.setState({
    				disabledDealerStep: true
    			});
            }

			localStorage.setItem('dealer', searchParams.get('dealer'))
			localStorage.setItem('page', 'dealer')
		} else {
            this.setState({
                disabledDealerStep: false,
            });

			localStorage.setItem('page', 'global')
		}

        // Disable model step, if model ID provided in GET
		if (searchParams.get('model') > 0) {

    		this.setState({
    			disabledModelStep: true
    		});

			localStorage.setItem('model', searchParams.get('model'))
		} else {
            this.setState({
                disabledModelStep: false,
            });
		}

		// Show contacts sidebar
		if (p.includes('contact')) {
			this.setState({
				showContactSidebar: true,
        disabledDealerStep: lang !== 'lt'
			})
		}

		// Show service sidebar
		if (p.includes('service')) {
			this.setState({
				showServiceSidebar: true
			})
		}

		// Show test drive sidebar
		if (p.includes('test-drive')) {
			this.setState({
				showTestDriveSidebar: true
			})
		}

        // Show driving academy sidebar
		if (p.includes('driving-academy')) {
			this.setState({
				showAcademySidebar: true
			})
		}

        // Show preorder sidebar
		if (p.includes('preorder')) {
			this.setState({
				showPreorderSidebar: true
			})
		}

        // Show lottery sidebar
		if (p.includes('loterija')) {
			this.setState({
				showLotterySidebar: true
			})
		}
	}

	_loadData = (lang, country) => {
		axios
			.get(window.API + '/' + lang + '/api/index/' + country + '?dealer=' + (searchParams.get('dealer') ? searchParams.get('dealer') : ''))
			.then(result => {
				this.setState({
					models: result.data.models,
					dealers: result.data.dealers,
					settings: result.data.settings,
                    events: result.data.events,
					translations: result.data.translate
				});
				localStorage.setItem('token', result.data.token)

                // Skip dealer section if only one dealer
                if (this.state.dealers.length === 1) {
                    this.setState({
                        disabledDealerStep: true,
                    });
                    localStorage.setItem('dealer', this.state.dealers[0].id)
                } else {
                    // Check for the test-drive dealers
                    if (p.includes('test-drive') && !searchParams.get('dealer') || p.includes('preorder') && !searchParams.get('dealer')) {
                        let testDriveDealers = 0;

                        for (let index = 0; index < this.state.dealers.length; index++) {
                            if (this.state.dealers[index].testdrive === 1) {
                                testDriveDealers++;
                                localStorage.setItem('dealer', this.state.dealers[index].id);
                            }
                        }

                        this.setState({
                            disabledDealerStep: testDriveDealers <= 1
                        });
                    }
                }
			})
			.catch(error => alert(error));
	}


	_setupSidebar = (step, show) => {
		this.setState({formStep: step});
		this.setState({showSidebar: show})
	}


	render() {

		let sidebar;

		if (this.state.showSidebar) {
			if (this.state.showContactSidebar) {
				sidebar = <SidebarContact
					step={this.state.formStep}
					show={this.state.showSidebar}
					translate={this.state.translations}
					settings={this.state.settings}
					disableDealer={this.state.disabledDealerStep}
                    disableModel={this.state.disabledModelStep}
				/>
			}

			if (this.state.showServiceSidebar) {
				sidebar = <SidebarService
					step={this.state.formStep}
					show={this.state.showSidebar}
					translate={this.state.translations}
					settings={this.state.settings}
					disableDealer={this.state.disabledDealerStep}
                    disableModel={this.state.disabledModelStep}
				/>
			}

			if (this.state.showTestDriveSidebar) {
				sidebar = <SidebarTestDrive
					step={this.state.formStep}
					show={this.state.showSidebar}
					translate={this.state.translations}
					settings={this.state.settings}
					disableDealer={this.state.disabledDealerStep}
                    disableModel={this.state.disabledModelStep}
				/>
			}

            if (this.state.showAcademySidebar) {
				sidebar = <SidebarAcademy
					step={this.state.formStep}
					show={this.state.showSidebar}
					translate={this.state.translations}
					settings={this.state.settings}
				/>
			}

            if (this.state.showPreorderSidebar) {
				sidebar = <SidebarPreorder
					step={this.state.formStep}
					show={this.state.showSidebar}
					translate={this.state.translations}
					settings={this.state.settings}
                    disableDealer={this.state.disabledDealerStep}
                    disableModel={this.state.disabledModelStep}
				/>
			}

            if (this.state.showLotterySidebar) {
				sidebar = <SidebarLottery
					step={this.state.formStep}
					show={this.state.showSidebar}
					translate={this.state.translations}
					settings={this.state.settings}
				/>
			}
		}

		return (
			<div>
				<div className="form__wrap">

                    <Helmet>
                        <title>{this.state.translations.page_title}</title>
                    </Helmet>


					{sidebar}

					<Router>

						{/* Contact form routes*/}
						<Route exact path="/:country/:lang/contact"
									 render={(props) => <FormUserInformation
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings} {...props}/>}
						/>

						<Route exact path="/:country/:lang/contact-choose-dealer"
									 render={(props) => <FormChooseDealer
										 sidebarSetup={this._setupSidebar}
                                         disableModel={this.state.disabledModelStep}
										 dealers={this.state.dealers}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/contact-question"
									 render={(props) => <FormSubject
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 sidebarSetup={this._setupSidebar}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/contact-summary"
									 render={(props) => <FormSummary
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/contact-success"
									 render={(props) => <FormSuccess
										 dealers={this.state.dealers}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 sidebarSetup={this._setupSidebar}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						{/*	Service form routes*/}

						<Route exact path="/:country/:lang/service"
									 render={(props) => <ServiceFormUserInformation
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/service-car-info"
									 render={(props) => <ServiceCarInfo
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/service-choose-dealer"
									 render={(props) => <ServiceChooseDealer
										 sidebarSetup={this._setupSidebar}
                                         disableModel={this.state.disabledModelStep}
										 dealers={this.state.dealers}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/service-choose-time"
									 render={(props) => <ServiceChooseTime
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/service-summary"
									 render={(props) => <ServiceSummary
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/service-success"
									 render={(props) => <ServiceSuccess
										 dealers={this.state.dealers}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 sidebarSetup={this._setupSidebar}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						{/*	Test drive form routes */}
						<Route exact path="/:country/:lang/test-drive"
									 render={(props) => <TestDriveFormUserInformation
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/test-drive-choose-model"
									 render={(props) => <TestDriveFormChooseModel
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 models={this.state.models}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/test-drive-choose-dealer"
									 render={(props) => <TestDriveFormChooseDealer
										 sidebarSetup={this._setupSidebar}
                                         disableModel={this.state.disabledModelStep}
										 dealers={this.state.dealers}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/test-drive-choose-time"
									 render={(props) => <TestDriveFormChooseTime
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/test-drive-summary"
									 render={(props) => <TestDriveFormSummary
										 sidebarSetup={this._setupSidebar}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

						<Route exact path="/:country/:lang/test-drive-success"
									 render={(props) => <TestDriveFormSuccess
										 dealers={this.state.dealers}
										 disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
										 sidebarSetup={this._setupSidebar}
										 translate={this.state.translations}
										 settings={this.state.settings}{...props}/>}
						/>

                        {/*	Driving academy form routes */}
                        <Route exact path="/:country/:lang/driving-academy"
                                     render={(props) => <AcademyFormUserInformation
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/driving-academy-choose-event"
                                     render={(props) => <AcademyFormChooseEvent
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         events={this.state.events}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/driving-academy-choose-time"
                                     render={(props) => <AcademyFormChooseTime
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/driving-academy-summary"
                                     render={(props) => <AcademyFormSummary
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/driving-academy-success"
                                     render={(props) => <AcademyFormSuccess
                                         dealers={this.state.dealers}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         sidebarSetup={this._setupSidebar}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />


                        {/*	Preorder form routes */}
                        <Route exact path="/:country/:lang/preorder"
                                     render={(props) => <PreorderUserInformation
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/preorder-choose-dealer"
                                     render={(props) => <PreorderChooseDealer
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         dealers={this.state.dealers}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/preorder-summary"
                                     render={(props) => <PreorderSummary
                                         sidebarSetup={this._setupSidebar}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/preorder-success"
                                     render={(props) => <PreorderSuccess
                                         dealers={this.state.dealers}
                                         disableDealer={this.state.disabledDealerStep}
                                         disableModel={this.state.disabledModelStep}
                                         sidebarSetup={this._setupSidebar}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        {/*	Preorder form routes */}
                        <Route exact path="/:country/:lang/loterija"
                                     render={(props) => <LotteryUserInformation
                                         sidebarSetup={this._setupSidebar}
                                         dealers={this.state.dealers}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

                        <Route exact path="/:country/:lang/loterija-success"
                                     render={(props) => <LotterySuccess
                                         sidebarSetup={this._setupSidebar}
                                         dealers={this.state.dealers}
                                         translate={this.state.translations}
                                         settings={this.state.settings}{...props}/>}
                        />

					</Router>
				</div>
			</div>
		)
	}
}

export default App;
