import React from 'react';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formChooseDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editLink: '?edit',
			editBtn: false,
			selectedValue: 'not-selected',
			selectedName: '',
			showError: false,
			formStep: 2
		}
	}

	componentDidMount() {
        amplitude.getInstance().init("849d00a092bb01538ad10fb5ddcd75d5");

		this.props.sidebarSetup(this.state.formStep, true);

		if (this.props.location.search === this.state.editLink) {
			this.setState({
				selectedValue: localStorage.getItem('dealer'),
				editBtn: false
			})

		} else {
			this.setState({
				editBtn: true
			})
		}
	}


	handleChange = (e) => {

		this.setState({
			selectedValue: e.target.value,
			selectedName: e.target.options[e.target.selectedIndex].text
		})

		if (e.target.value !== 'not-selected') {
			this.setState({showError: false})
		}
	};

	handleSubmit = () => {
		if (this.state.selectedValue !== 'not-selected') {
            window.parent.postMessage('scroll', '*');
			localStorage.setItem('dealer', this.state.selectedValue);
			localStorage.setItem('dealer-name', this.state.selectedName);

            let event = "Step 2 - dealer selected";
            let eventProperties = {
                "form": "contact",
                "country": this.props.match.params.country,
                "language": this.props.match.params.lang
            };

            amplitude.getInstance().logEvent(event, eventProperties);

			if (this.props.location.search === this.state.editLink) {
				this.props.history.push('contact-summary')
			} else {
				this.props.history.push('contact-question')
			}

		} else {
			this.setState({showError: true})
		}
	};

	render() {

		let translate = this.props.translate;
		let dealers = this.props.dealers;

		return (


			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.dealers_title}</h4>

					<form>

						<div className="aui-select aui-js-select aui-js-response aui-select--floating-label">
							<select value={this.state.selectedValue} className="aui-select__input" id="choose-dealer"
											onChange={this.handleChange}>

								<option className="aui-select__input-label"
												defaultValue="not-selected"
												value="not-selected">
									{translate.dealers_choose_dealer}
								</option>

                {dealers.map((dealer, index) => {
                  if (dealer.testdrive === 1)
  									return <option key={index} value={dealer.id}>{dealer.title}</option>
                  }
								)}
							</select>
							<label className="aui-select__label" htmlFor="choose-dealer">{translate.dealers_choose_dealer}</label>
							<span className={'aui-textfield__error ' + (this.state.showError ? 'visible' : '')}>
										{translate.dealers_error_not_selected}
										</span>
						</div>

						<div className="buttons-wrap">
							<button
								className="aui-button aui-button--primary aui-js-response"
								type="button"
								onClick={this.handleSubmit}>

								{this.state.editBtn ? translate.dealers_submit : translate.common_element_save_btn}
							</button>
						</div>

					</form>
				</div>
			</div>

		)
	}
}

export default formChooseDealer;
